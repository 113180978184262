import React, { useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import CriarContent from '../../criar/criar';
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarEstabelecimentosVerificar, listarGruposEstabelecimentos, listarMeusProjetosEmpresas, listarMeusProjetosEstabelecimentos, removeListMeuProjetoTab, removerEstabelecimento } from '../../redux/meusProjetosActions';
import { listarPaises, modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import Verificar from './verificar';
import Grupos from './grupos';
import { IF, filterList, history } from '../../../../helpers';
import { typeStatusCustom } from '../../redux/meusProjetos';
import ObterMais from '../../obterMais';
import { modalAlertaInfo, modalClose, modalAlertaClean, informeNaoMostrar } from '../../../../layout/redux/layoutActions'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const location = useLocation();
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
  const [estabelecimentos, setEstabelecimentos] = useState([])
  
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)
  const datarenovacao = sistema.dataAtualizacaoAssinatura

  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [122];
  const idarray1 = [123];


  const formatarDataRenovacao = (data, idioma) => {
    // Mapeia o idioma 'esp' para 'es-ES' para compatibilidade com Intl.DateTimeFormat
    if (!data) {
      //console.log("DATA NÃO É NO FORMATO VÁLIDO")
      return "";
    }
    //console.log("DATA é VÁLIDO1!!!")
    const locale = idioma === "esp" ? "es-ES" : idioma;
    //console.log("DATA é VÁLIDO2!!!")
    return new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(new Date(data));
  };

    const datarenovacaoformatada = formatarDataRenovacao(datarenovacao, sistema.idioma);

    useEffect(()=> {
      if (idProjetos) {
        dispatch(listarMeusProjetosEmpresas({idProjeto: idProjetos}))
        dispatch(listarMeusProjetosEstabelecimentos({...meuProjeto.estabelecimentosPaginate, idProjeto: idProjetos}))
        dispatch(listarGruposEstabelecimentos(idProjetos))
        // dispatch(listarPaises(sistema.idioma))
      }
    }, [idProjetos])

    useEffect(()=> {
      const query = new URLSearchParams(location.search);
      const searchTerm = query.get('estabelecimentos');
      if (meuProjeto?.estabelecimentos?.length && searchTerm) {
        setEstabelecimentos(filterList(searchTerm, 'nomeEstabelecimento', meuProjeto.estabelecimentos))
      } else {
        setEstabelecimentos(meuProjeto.estabelecimentos)
      }
    }, [meuProjeto.estabelecimentos, history.location])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeEstabelecimento',
      text: nls.table.nome,
      className: '',
    },
    {
      column: 'statusCustom',
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.table.status}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </div>
      ),
      className: '',
    },
    {
      column: 'nomeEmpresa',
      text: nls.table.empresa,
      className: '',
    },
    {
      column: 'grupoEstabelecimento',
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.table.grupo}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray1} />
        </div>
      ),
      className: '',
    },
    {
      column: 'urlTripAdvisorEstabelecimento',
      text: 'TripAdvisor',
      className: '',
    },
    {
      column: 'endereco',
      text: nls.table.endereco,
      className: '',
    },
    {
      column: 'bairro',
      text: nls.table.bairro,
      className: '',
    },
    {
      column: 'cidade',
      text: nls.table.cidade,
      className: '',
    },
    {
      column: 'estado',
      text: nls.table.estado,
      className: '',
    },
    {
      column: 'cep',
      text: nls.table.cep,
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const [situacaoAtual, setSituacaoAtual] = useState(false); // Estado para controlar a situação atual

  //VERIFICAR O ERRO AQUI...MARCA UM ESTABELECIMENTO SELECIONADO E COM SITUACAO 'A' COMO FALSE...
  const handleCheckboxSelect = e => {
    let updatedSelects;
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.estabelecimentos.filter(f=> f.situacao !== 'AD').map(p=> {  all = {...all, [p.idEstabelecimento]: e.value} })
      updatedSelects = all;
      setForm({...form, selects: all})
    } else {
      updatedSelects = {
        ...form.selects,
        [e.name]: e.value,
      };
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
    // Verifica se há algum estabelecimento com situacao === "A" selecionado
    const selectedWithSituacaoA = meuProjeto.estabelecimentos.some(
      f => updatedSelects?.[f.idEstabelecimento] && f.situacao === 'A'
    );
  
    //console.log("SITUAÇÃO ATUAL ESTABELECIMENTO SELECIONADO COM 'A'", selectedWithSituacaoA);
  
    setSituacaoAtual(selectedWithSituacaoA); // Atualiza o estado situacaoAtual
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    dispatch(listarMeusProjetosEstabelecimentos({...meuProjeto.estabelecimentosPaginate, idProjeto: idProjetos, pageNumber: e }))
  }

  const handlePerPage = e => {
    dispatch(listarMeusProjetosEstabelecimentos({...meuProjeto.estabelecimentosPaginate, idProjeto: idProjetos, perPage: Number(e.value.id)}))
  }
  
  const handleAddModal = e => {
    if (e?.idEstabelecimento) {
      setInfomodal(e)
      dispatch(modalOpen('criarEstabelecimentosMeuProjeto'))
    } else if (sistema?.creditoConta?.creditoEstabelecimentos) {
      setInfomodal({})
      dispatch(modalOpen('criarEstabelecimentosMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }
  
  const handleRemove = e => {
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true )
    //console.log("E",e)
    //console.log("Tipo de e:", typeof e);
//console.log("É um Array?", Array.isArray(e));
    console.log("returnedHas", returnedHas)
    // returnedHas.map(id=> {
    //   dispatch(removeListMeuProjetoTab({id, name: 'estabelecimentos'}))
    // })
    dispatch(removerEstabelecimento(returnedHas, idProjetos, nlsPopup))
    handleCheckboxSelect({name: 'all', value: false})
    dispatch(modalAlertaClean())
  }

  const handleImportarModal = e => {
    if (sistema?.creditoConta?.creditoEstabelecimentos) {
      dispatch(modalOpen('importarEstabelecimentoMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
  }

  const handleVerificarModal = e => {
    if (sistema.creditoConta?.creditoEstabelecimentos) {
      if (e.idEstabelecimento) {
        console.log("VERIFICAR PARTE1")
        dispatch(listarEstabelecimentosVerificar(e.idEstabelecimento))
        setInfomodal(e)
      } else {
        console.log("VERIFICAR PARTE2")
        setInfomodal({})
      }
      console.log("VERIFICAR PARTE3")
      dispatch(modalOpen('verificarEstabelecimentosMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    dispatch(modalAlertaClean())
    }
  }

  const statusTypes = e => {
    switch (e.status) {
      case 'procurando':
        return <span className='flag-warning'>Procurando estabelecimento</span>;
      case 'sucesso':
        return <span className='flag-info'>Verificação concluída</span>;
      default:
        return <span className='flag-primary' onClick={()=> handleVerificarModal(e)}>Realizar verificação</span>;
    }
  }

  const handleGruposModal = e => {
    dispatch(modalOpen('gruposEstabelecimentoMeuProjeto'))
  }
  
  return (
    <div className='box-meus-projetos-estabelecimentos'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.title}</h5>
        <div>
        <Button
            type="link"
            color="default"
            onClick={() => {
              if (situacaoAtual) {
                // Executa se situacaoAtual for true
                dispatch(
                  modalAlertaInfo({
                    ...nlsPopup[12],
                    show: true, // Exibe o modal
                    btnAction: () => handleRemove(form.selects), // Ação no botão de confirmação
                    textos: {
                      DataRenovacaoAssinatura: datarenovacaoformatada, // Informações personalizadas
                    },
                  })
                );
              } else {
                // Executa se situacaoAtual for false
                handleRemove(form.selects);
              }
            }}
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.btnExcluir}</Button>
          <Button
            color='primary'
            onClick={()=> handleGruposModal()}
          >{nls.btnGrupos}</Button>
            <Button
              color='default'
              onClick={()=> handleImportarModal()}
            ><IcoUpload /> {nls.btnImportar}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls.btnAdd}</Button>
        </div>
      </div>
      <LoadingContent
        show={false}
      >
        <List
          header={headerDefault}
          data={estabelecimentos}
          listCustom={e=> {
            const item = e
            item.latitudeLongitude = e.coordenadas
            item.checkboxCustom =  e.situacao !== 'AD'? <>
              <Checkbox 
                name={e.idEstabelecimento}
                checked={form.selects?.[e.idEstabelecimento]}
                action={c=> handleCheckboxSelect({name: e.idEstabelecimento, value: c})}
              />
            </> :null
            // item.statusCustom = e.statusEstabelecimento //statusTypes(e)
            
            const statusInfo = typeStatusCustom(e.statusEstabelecimento)

            //Ajuste para mostrar botão apenas quando e.statusEstabelecimento === "RV"
            //item.statusCustom = e.statusEstabelecimento === "Realizar Verificação" ? (
              item.statusCustom = e.situacao === "RV" ? ( 
          <span
            style={{ cursor: 'pointer' }}
            className={statusInfo?.class}
            onClick={()=> handleVerificarModal(e)}
          >
            {e.statusEstabelecimento}
          </span>
        ) : (
          <span>{e.statusEstabelecimento}</span>
        );

            item.actions = e.podeSerEditado? <>
              <Button
                type='link'
                color='default'
                onClick={()=> handleAddModal(e)}
              ><IcoPencil /> {nls.btnEditar}</Button>
            </> :null

            return item
          }}
          paginate={meuProjeto.estabelecimentosPaginate}
          actionPaginate={(e)=> handlePaginate(e)}
          handlePerPage={(e)=> handlePerPage(e)}
          nls={nls.paginacao}
        />
      </LoadingContent>
      <Add data={infomodal} />
      <Importar />
      <Verificar data={infomodal} />
      <Grupos />
      <ObterMais type='conteudoEstabelecimentos'/>
    </div>
  )
}
