import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoCalendar, IcoCicle } from '../../../../components'
import './filter.scss'
import FilterCiclo from './filterCiclo'
import FilterData from './filterData'
import { filterHandle, putFiltersProvisorio, setFiltersProvisorio } from '../redux/rankingcsatActions'

export default () => {
  const dispatch = useDispatch()
  const { projeto, grupo, filters, listas: {typeSearch}, ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio } = useSelector(state=> state.rankingcsatState)

  const nls = require(`../nls/${sistema.idioma}.json`)

  
  
  useEffect(()=> {
    let filtros = filters
    if (!filters?.ciclo?.anoMes  && ciclos.length) {
      filtros = {...filtersProvisorio, ...filtros, ciclo: ciclos[ciclos.length - 1]}
    }
    dispatch(setFiltersProvisorio(filtros))
  }, [ciclos])

  const handleSearching = (params) => {
    handleSearchingValid(params)
    // if (params.typeSelect.id === 'Ciclo') {
    // }
    // if (params.typeSelect.id === 'Data') {
    //   if (params.dataInicial && params.dataFinal) {
    //     handleSearchingValid(params)
    //   }
    // }
  }
  
  const handleSearchingValid = (params) => {
      console.log("1111 FILTERHANDLE COM IDIOMAID = " + sistema.idiomaIdValue)
      const labelgraf = {
        atual: nls.labelgrafico.atual,
        anterior: nls.labelgrafico.anterior
      };
      
      dispatch(filterHandle({projetoID: projeto.id, grupo, params, filtersProvisorio, typeSelect: params.typeSelect, idioma: sistema.idiomaIdValue, labelgraf}))
      
    }
  
  const contentFilterType = (e) => {
    switch (e?.id) {
      case 'Ciclo':
        return <FilterCiclo />;
      case 'Data':
        return <FilterData />;
      default:
        return;
    }
  }
  
  return (
    <div className='box-rankingcsat-search box-content'>
      <div className='box-rankingcsat-search-box'>
        <div className='box-rankingcsat-search-type'>
          {
            typeSearch.map((type)=> {
              return <Button
                color={'primary'}
                type={type.id === filtersProvisorio?.typeSelect?.id?'btn':'link'}
                size='small'
                onClick={()=> dispatch(putFiltersProvisorio({name: 'typeSelect', value: type}))}
                key={type.id}
              >
                {type.label}
              </Button> 
            })
          }
          <p>{filtersProvisorio?.typeSelect?.text === 'Filtrar por Ciclo' ? nls.filtrarporciclo : nls.filtrarpordata}</p>
        </div>
        <div>
          {contentFilterType(filtersProvisorio?.typeSelect)}
        </div>

      </div>
      <div className='box-rankingcsat-search-action'>
        <Button
          color='primary'
          size='large'
          onClick={()=> handleSearching(filtersProvisorio)}
        >{nls.pesquisar}</Button>          
      </div>
    </div>
  )
}
