import React, { useEffect } from 'react';
import { useRef } from 'react';
import useOutsideClick from '../useOusideClick/useoutsideclick';
import { useState } from 'react';
import Portal from '../portal/portal';
import { IcoHelpCircle } from '../icon/icon';

export const Popover = ({ 
  actionText=<small><IcoHelpCircle /></small>,
  open=false,
  children
}) => {
  const [show, setShow] = useState(false)
  const [selectCoordinates, setSelectCoordinates] = useState({});

  useEffect(()=> {
    setShow(open)
  }, [open])

  const handleDropdown = (elem, value) => {
    if (elem) {
      let div = elem.target;
      let rect = div.getBoundingClientRect(),
        eixoX = rect.left,
        eixoY = rect.top,
        width = rect.width,
        height = rect.height;


      // let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let coor = {};
      if (Math.ceil(eixoY + height) + 300 >= windowHeight) {
        coor = { left: eixoX, bottom: windowHeight - eixoY, top: 'auto' };
      } else {
        coor = { left: eixoX, top: eixoY + height + 1 };
      }
      setSelectCoordinates({ ...coor, position: 'absolute', minWidth: width, width: 'auto' });
    } else {
      setSelectCoordinates({})
    }
    setShow(value);
  };

  const ref = useRef(null);
  useOutsideClick(ref, (e) => {
    if (show && !e) {
      setShow(false)
    }
  });

  return (
    <div className={`box-popover`} >
      <div className="box-popover-head" onClick={(e)=> handleDropdown(e, !show)} >
        {actionText}
      </div>
      {show?<Portal name={'box-popover'}><div className="box-popover-content" style={selectCoordinates} ref={ref} >{children}</div></Portal>:null}
    </div>
  );
};
