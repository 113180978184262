import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, MaskData, Modal } from '../../../components'
import { modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions'
import { AtualizarAssinaturaContaXD, calculadoraContaEmpresarial, listaPeriodos, listarAtivos, setAtivos } from '../redux/minhasAssinaturasActions'

import { PopoverCentralAjuda } from '../../../components/popovercentralajuda/popovercentralajuda'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { assinatura, listas, ativos, avaliacoes } = useSelector(state=> state.minhasAssinaturasState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [form, setForm] = useState({periodo: {}})

  const nlsContexto = require(`../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [114];
  
  useEffect(()=> {
    if (listas?.periodos.length) {
      const list = listas?.periodos.find(f=> f.id === 4)
      setForm({...form, periodo: list})
    }
  }, [listas?.periodos])

  useEffect(()=> {
    if (statusModal === 'assinaturas-alterar-projeto') {
      dispatch(listarAtivos({consumoConta: sistema.consumoConta, moeda: usuario.moedaIso4217, tipo: 'CE', status: 'vazio', idioma: sistema.idiomaIdValue}))
      dispatch(listaPeriodos({idioma: sistema.idiomaIdValue}))
    }
  }, [statusModal, sistema.idioma, sistema.consumoConta])

  const handleChange = e => {
    const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
    dispatch(setAtivos(e))
    dispatch(calculadoraContaEmpresarial({ativos: listaAtivos, moeda: usuario.moedaIso4217}))
  }

  const handleCancelar = e => {
    dispatch(modalClose())
  }

  const handleConcordar = e => {
    console.warn(e, 'handleConcordar');

    const params = {
      idPeriodo: e.periodo.id,
      moeda: usuario.moedaIso4217,
      dataAgendadaAlteracao: assinatura.dataRenovacaoAssinatura,
      ativos: [...ativos, {
        descricao: "Avaliações mensais",
        id: 6,
        quantidadeDesejada: avaliacoes.totalQueriesSemQueryPura30,
        valor: avaliacoes.totalQueriesSemQueryPura30
      }]
    }
    dispatch(AtualizarAssinaturaContaXD(params, nlsPopup))
  }
  
  const handleSalvar = e => {
    console.warn(e, 'assinaturas-alterar-projeto');
    dispatch(modalAlertaInfo({
      ...nlsPopup['1'], 
      btnAction: ()=> handleConcordar(e, 'assinaturas-economizar'),
      show: true,
      textos: {
        DataRenovacaoAssinatura: MaskData(assinatura.dataRenovacaoAssinatura, 'DD/MM/YYYY', sistema.idioma),
      }
    }))
    handleCancelar()
  }
  
  const handleChangePeriodo = e => {
    setForm({...form, periodo: e})
  }
  
  const valorTotal = e => {
    const val = e?.find(f=> Number(f.id) === Number(form.periodo.id))
    return val?.valor
  }
  
  
  return (
    <Modal
      name='assinaturas-adquirir-ativos'
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.AlterarContaEmpresarial}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </div>
      }
      open={statusModal === 'assinaturas-alterar-projeto' ? true : false}
      close={() => handleCancelar()}
      closeText={nls.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCancelar()}
        >{nls.cancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar(form)}
        >{nls.AlterarContaEmpresarial}</Button>
      </>}

    >
      <div>
        <div className='periodos-lista'>
          <strong>{nls.selecionePeriodo}</strong>
          <div>
            {listas?.periodos?.map(e=> {
              return <div key={e.id}>
                <Button
                  type='btn circle'
                  color={form?.periodo?.id === e.id ? 'primary' : 'default'}
                  size='small'
                  onClick={()=> handleChangePeriodo(e)}
                >{e.descricao}</Button>
              </div>
            })}
          </div>
        </div>
        <div className='ativos-lista-title'>
          <h5>{nls.adicionarAtivos}</h5>
        </div>
        <div className='ativos-lista'>
          {ativos.map(e=> {
            const min = e.id <= 2 ? e.id : 0
            return <div key={e.id} className='ativos-lista-item'>
              <div className='ativos-lista-item-info'>
                <strong>{e.descricao}</strong>
              </div>
              <div className='ativos-lista-item-input'>
                  <Input 
                    type='number'
                    value={e.quantidadeDesejada}
                    action={a=> handleChange({...e, quantidadeDesejada: a.value})}
                    min={min}
                  />
              </div>
            </div>
          })}
          <div className='ativos-lista-item'>
            <div className='ativos-lista-item-info'>
              <strong>{nls.avaliacoesMensais}</strong>
            </div>
            <div className='ativos-lista-item-input'>
              <div className='flag-primary'>
                {avaliacoes.totalQueriesSemQueryPura30}
              </div>
            </div>
          </div>
        </div>
        <div className='ativos-info'>
          <span>{nls.valorTotalAssinatura}: <strong>{valorTotal(avaliacoes.valores)}</strong> </span>
        </div>
      </div>
    </Modal>
  )
}
