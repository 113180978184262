import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, RadioButton, Select } from '../../../../components'
import { putFiltersProvisorio } from '../redux/csatconcorrentesActions'

export default () => {
  const dispatch = useDispatch()
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.csatconcorrentesState)

  const [ciclo1, setCiclo1] = useState({})
  const [ciclos1, setCiclos1] = useState([])
  const nls = require(`../nls/${sistema.idioma}.json`)

  useEffect(()=> {
   init()
 }, [])


 const init = () => {
   const data = ciclo?.anoMesCiclo?.split('-') 
   if (data) {
     const mes = nls.nomesMeses.find(nm=> Number(nm.id) === Number(data[1]))  
     const ciclo1Result = {...ciclo, mesAno:`${mes.name}-${data[0]}`, anoMes:`${data[0]}-${data[1]}`, ano: data[0], mes: data[1]}
   //Linha de baixo é necessária porque a primeira vez ciclo vem sem valor correto do idioma...
     dispatch(putFiltersProvisorio({ name: 'ciclo', value: ciclo1Result }))

   }
 
   const ciclos1Result = ciclos.map(ciclolido => {
     const data = ciclolido.anoMesCiclo.split('-');
     const mes = nls.nomesMeses.find(nm => Number(nm.id) === Number(data[1])); 
     return {
       ...ciclolido,
       mesAno: `${mes.name}-${data[0]}`,
       anoMes: `${data[0]}-${data[1]}`,
       ano: data[0],
       mes: data[1]
     };
   });
   setCiclos1(ciclos1Result)
 }


  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }

  const desativar = nls.desativar
  const ativar = nls.ativar

  const compararCiclosList = [
   {
      id: 'desativar',
      name: desativar,
   },
   {
      id: 'ativar',
      name: ativar,
   }
  ]
  
  return (
   <div className='box-csatconcorrentes-search csatconcorrentes-search-ciclo'>
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos1}
         selected={ciclo}
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
      <RadioButton
         name='compararCiclos'
         label={nls.compararciclos}
         options={compararCiclosList}
         checked={compararCiclos}
         action={(e)=> handleFormFilter(e)}
      />
   </div>
  )
}
