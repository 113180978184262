import React, { useState } from 'react'
import { useParams, useLocation  } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import CriarContent from '../../criar/criar';
import { Button, Checkbox, IcoAdd, IcoBin, IcoPencil, IcoUpload, List, LoadingContent } from '../../../../components';
import { useEffect } from 'react';
import { listarMeusProjetosEmpresas, removeListMeuProjetoTab, removerEmpresa } from '../../redux/meusProjetosActions';
import { modalOpen } from '../../../../layout/redux/layoutActions';
import Add from './add';
import Importar from './importar';
import { IF, filterList, history } from '../../../../helpers';
import { typeStatusCustom } from '../../redux/meusProjetos';
import ObterMais from '../../obterMais';
import { modalAlertaInfo, modalClose, modalAlertaClean, informeNaoMostrar } from '../../../../layout/redux/layoutActions'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const location = useLocation();
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { meuProjeto, paginate } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({selects: {}})
  const [infomodal, setInfomodal] = useState({selects: {}})
  const [empresas, setEmpresas] = useState([])
  
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)
  const datarenovacao = sistema.dataAtualizacaoAssinatura

  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [118];


  
  const formatarDataRenovacao = (data, idioma) => {
    // Mapeia o idioma 'esp' para 'es-ES' para compatibilidade com Intl.DateTimeFormat
    if (!data) {
      //console.log("DATA NÃO É NO FORMATO VÁLIDO")
      return "";
    }
    //console.log("DATA é VÁLIDO1!!!")
    const locale = idioma === "esp" ? "es-ES" : idioma;
    //console.log("DATA é VÁLIDO2!!!")
    return new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(new Date(data));
  };

    const datarenovacaoformatada = formatarDataRenovacao(datarenovacao, sistema.idioma);

  useEffect(()=> {
    if (idProjetos) {
      dispatch(listarMeusProjetosEmpresas({...meuProjeto.empresasPaginate, idProjeto: idProjetos}))
    }
  }, [idProjetos])

  useEffect(()=> {
    const query = new URLSearchParams(location.search);
    const searchTerm = query.get('empresas');
    if (meuProjeto?.empresas?.length && searchTerm) {
      setEmpresas(filterList(searchTerm, 'nomeEmpresa', meuProjeto.empresas))
    } else {
      setEmpresas(meuProjeto.empresas)
    }
  }, [meuProjeto.empresas, history.location])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form.selects?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeEmpresa',
      text: nls.table.nome,
      className: '',
    },
    {
      column: 'statusCuston',
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.table.status}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </div>
      ),
      sort: '',
      className: '',
    },
    {
      column: 'urlFacebookEmpresa',
      text: 'Facebook',
      className: '',
    },
    {
      column: 'urlInstagramEmpresa',
      text: 'Instagram',
      className: '',
    },
    {
      column: 'urlTwitterEmpresa',
      text: 'Twitter',
      className: '',
    },
    {
      column: 'urlYoutubeEmpresa',
      text: 'Youtube',
      className: '',
    },
    {
      column: 'urlTiktokEmpresa',
      text: 'Tiktok',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      meuProjeto.empresas.filter(f=> f.situacao !== 'AD').map(p=> {  all = {...all, [p.empresaId]: e.value} })
      setForm({...form, selects: all})
    } else {
      setForm({...form, selects: {...form.selects, [e.name]: e.value}})
    }
  }

  const selectedHas = Object.keys(form.selects).filter(p=> form.selects[p] ).length

  const handlePaginate = e => {
    dispatch(listarMeusProjetosEmpresas({...meuProjeto.empresasPaginate, idProjeto: idProjetos, pageNumber: e}))
  }

  const handlePerPage = e => {
    dispatch(listarMeusProjetosEmpresas({...meuProjeto.empresasPaginate, idProjeto: idProjetos, perPage: Number(e.value.id)}))
  }
  
  const handleAddModal = e => {
    if (e?.empresaId) {
      setInfomodal(e)
      dispatch(modalOpen('criarEmpresaMeuProjeto'))
    } else if (sistema?.creditoConta?.creditoEmpresas) {
      setInfomodal({})
      dispatch(modalOpen('criarEmpresaMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
    dispatch(modalAlertaClean())
  }
  
  const handleRemove = e => {
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true).map(f=> Number(f))
    // returnedHas.map(id=> {
    //   dispatch(removeListMeuProjetoTab({id, name: 'empresas'}))
    // })
    dispatch(removerEmpresa(returnedHas, idProjetos, nlsPopup))
    handleCheckboxSelect({name: 'all', value: false})
    dispatch(modalAlertaClean())
  }

  const handleImportarModal = e => {
    if (sistema?.creditoConta?.creditoEmpresas) {
      dispatch(modalOpen('importarEmpresaMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais'))
    }
    dispatch(modalAlertaClean())
  }
  
  return (
    <div className='box-meus-projetos-empresas'>
      <div className='box-meus-projetos-topinfo'>
        <h5>{nls.title}</h5>
        <div>
          <Button
            type='link'
            color='default'
            onClick={() =>
              dispatch(
                modalAlertaInfo({
                  ...nlsPopup[12], // Inclui o conteúdo de nlsPopup[12]
                  show: true, // Garante que o modal será exibido
                  btnAction: () => handleRemove(form.selects), // Define a ação no botão de confirmação
                  textos: {
                    DataRenovacaoAssinatura: datarenovacaoformatada, // Adiciona informações personalizadas
                  },
                })
              )
            }
            disabled={selectedHas === 0? true:false}
          ><IcoBin /> {nls.btnExcluir}</Button>
          <Button
            color='default'
            onClick={() =>
              dispatch(
                modalAlertaInfo({
                  ...nlsPopup[13], // Inclui o conteúdo de nlsPopup[12]
                  show: true, // Garante que o modal será exibido
                  btnAction: () => handleImportarModal(), // Define a ação no botão de confirmação
                  
                })
              )
            }
          ><IcoUpload /> {nls.btnImportarEmpresas}</Button>
          <Button
            color='primary circle'
            onClick={() =>
              dispatch(
                modalAlertaInfo({
                  ...nlsPopup[13], // Inclui o conteúdo de nlsPopup[12]
                  show: true, // Garante que o modal será exibido
                  btnAction: () => handleAddModal(), // Define a ação no botão de confirmação
                  
                })
              )
            }
          ><IcoAdd /> {nls.btnAddEmpresa}</Button>
        </div>
      </div>
      <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={empresas}
            listCustom={e=> {
              const item = e
              const statusInfo = typeStatusCustom(e.statusSituacao)
              //item.statusCuston = <span style={{cursor: 'pointer'}} className={statusInfo?.class} onClick={()=>null}>{e.statusSituacao}</span>
              item.statusCuston = <span>{e.statusSituacao}</span>; // Apenas texto
              // e.status?nls.ativo:nls.inativo
              item.checkboxCustom = e.situacao !== 'AD' ? <>
                <Checkbox 
                  name={e.empresaId}
                  checked={form.selects?.[e.empresaId]}
                  action={c=> handleCheckboxSelect({name: e.empresaId, value: c})}
                />
              </> : null
              //e.status
              item.actions = true? <>
                <Button
                  type='link'
                  color='default'
                  onClick={()=> handleAddModal(e)}
                ><IcoPencil /> {nls.btnEditar}</Button>
              </> :null

              return item
            }}
            paginate={meuProjeto.empresasPaginate}
            actionPaginate={(e)=> handlePaginate(e)}
            handlePerPage={(e)=> handlePerPage(e)}
            nls={nls.paginacao}
          />
        </LoadingContent>
        <Add data={infomodal} />
        <Importar />
        <ObterMais type='conteudoEmpresas'/>
    </div>
  )
}
