import React from 'react';

export const gerarLista = (ids, nls) => {
  
/* CONTEÚDO PASSADO POR NLS
  const listaCompleta1 = [
    {
      id: 1,
      titulo: 'Titulo 1',
      conteudo: `1.Explore os projetos de cortesia disponíveis no sistema <br><br> para ter uma visão inicial do que oferecemos. <a href="#" class="link" data-ids="2,4">Veja mais</a>`,
      
    },
    {
      id: 2,
      titulo: 'Titulo 2',
      conteudo: `2.Vamos testar o link para o <a href="#" class="link" data-ids="1">Link 1</a> .`,
      
    },
    {
      id: 3,
      titulo: 'Titulo 3',
      conteudo: `3.Explore os projetos de cortesia disponíveis no sistema <br><br> para ter uma visão inicial do que oferecemos. <a href="#" class="link" data-ids="1,2">Voltar</a>`,
      
    },
    {
      id: 4,
      titulo: 'Titulo 4',
      conteudo: `4.Explore os projetos de cortesia disponíveis no sistema <br><br> para ter uma visão inicial do que oferecemos.`,
      
    },
  ];
*/
  const listaCompleta = nls.conteudos
  return ids ? listaCompleta.filter((item) => ids.includes(item.id)) : listaCompleta;
};

/*
export const paginateInit = () => {
  return {
    pageNumber: 1,
    totalPages: 1,
    totalElements: 0,
    perPage: 7,
  };
};
*/
