import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, Modal, Select } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { estabelecimentosVerificar, listarEstabelecimentosVerificar, putListMeuProjetoTab, setListMeuProjetoTab } from '../../redux/meusProjetosActions'


export default ({data}) => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state=> state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)

  const [form, setForm] = useState({})

  useEffect(()=> {
  }, [])


  const handlerCriar = e => {
    console.log("ENTROU EM VERIFICAR.JS")
    dispatch(estabelecimentosVerificar({IdItemVerificacao: e.idLojaRetorno, IdEstabelecimento: data.idEstabelecimento, idProjetos, nlsPopup}))      
    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }
  
  return (
    <Modal
      title={nls.configurar?.verificarEstabelecimentoTitle}
      size='medium'
      open={statusModal === 'verificarEstabelecimentosMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnSalvarEstabelecimento}</Button>
      </>}
    >
      <div className='modal-meuProjeto-estabelecimentos-verificar'>
        <p className='informe'>{nls.configurar?.informeClick}</p>
        <div>
          {meuProjeto.estabelcimentosVerificar?.map(e=> {
            return <div key={e.idLojaRetorno} onClick={()=> setForm(e)} className={e.idLojaRetorno === form.idLojaRetorno ? 'active' : ''}>
              <label>{e.endereco} - {e.cidade}</label>
              <span>{e.descricao}</span>
            </div>
          })}
        </div>
      </div>
    </Modal>
  )
}