import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../../components'
import './rankingclientenps.scss'
import Filter from './filter/filter'
import Grafico from './grafico'
import { carregarGrafico, filterHandle } from './redux/rankingclientenpsActions'
import { setInfoEmpresa, setProjeto } from '../../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const { filters, empresas, empresa, usuario: {nomeEmpresa}, sistema } = useSelector(state=> state.layoutState)
  const { projeto, grafico, totalAvaliacao, projetos, filtersProvisorio } = useSelector(state=> state.rankingclientenpsState)

  const nls = require(`../rankingclientenps/nls/${sistema.idioma}.json`)
  const nlsContexto = require(`../../centralAjuda/nlscontexto/${sistema.idioma}.json`)


  useEffect(()=> {
    if (projeto.id) {
      dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, params: {...filters, NomeEmpresa: nomeEmpresa}, filtersProvisorio, typeSelect: filters.typeSelect, idioma: sistema.idiomaIdValue}))
    }
  }, [projeto, empresa, sistema.idiomaIdValue])

  const handleFormFilter = (e) => {
    dispatch(setProjeto(e.value))
  }

  const handleFormFilterEmpresa = (e) => {
    dispatch(setInfoEmpresa(e.value))
  }
  
  return (
    <div className='box-rankingclientenps'>
      <div className='box-rankingclientenps-topinfo'>
        <div>
          <div className='box-rankingclientenps-topinfo-select'>
            <Select 
              name='projeto'
              label={nls.projeto}
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormFilter(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          <div className='box-npsconcorrentes-topinfo-select'>
            <Select 
              name='empresa'
              label={nls.empresaanalisada}
              options={empresas}
              selected={empresa}
              action={(e)=> handleFormFilterEmpresa(e)}
              selectedItem={false}
              optionLabel='descricaoEmpresa'
              optionValue='empresaId'
            />
          </div>
        </div>
        <div className='box-rankingclientenps-topinfo-avaliacao'>
            <label>{nls.numerodeavaliacoes}</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <Grafico nlsContexto={nlsContexto} nls={nls} />
    </div>
  )
}
