import { AddAlert } from "../../../components"
import { history, idRdm, numberAleatori, paramsApi } from "../../../helpers"
import api from "../../../helpers/api"
import { loaded, loading, modalAlertaClean, modalAlertaInfo, modalClose, setInfoEmpresa, setInfoGrupo, setListarEmpresas, setListarGrupos, setListarProjetos, setProjeto, verificaCreditoConta } from "../../../layout/redux/layoutActions"
import { gerarLista, gerarListaEmpresas, gerarListaEstabelecimentos, gerarListaProdutos, paginateInit } from "./meusProjetos"



export const setListMeusProjetos = payload => ({
  type: 'LIST_MEUS_PROJETOS',
  payload
})

export const setListMeuProjeto = payload => ({
  type: 'SET_MEUS_PROJETOS',
  payload
})

export const putListMeuProjeto = payload => ({
  type: 'PUT_MEUS_PROJETOS',
  payload
})

export const setFormProjeto = payload => ({
  type: 'SET_FORM_MEUS_PROJETO',
  payload
})

export const setFormProjetoLista = payload => ({
  type: 'SET_FORM_MEUS_PROJETO_LIST',
  payload
})

export const cleanFormProjeto = payload => ({
  type: 'CLEAN_FORM_MEUS_PROJETO',
  payload
})

export const setErroProjeto = payload => ({
  type: 'SET_MEUS_PROJETO_ERRO',
  payload
})

export const listarMeusProjetos = e => {
  return dispatch => {
    // dispatch(setListMeusProjetos(gerarLista()))
    api.get(`/Projeto/ListaProjetos`)
    .then(result => {
      const projetos = result.data?.informacoesProjetos.filter(p=> p.situacao !== 'I')?.map(p=> ({...p, id: p.idProjeto, nomeProjeto: p.descricao}))
      let projeto = projetos[0]
      if (e) {
        projeto = projetos.find(p=> String(p.idProjeto) === String(e))
      }

      console.log("ENTROU EM LISTAPROJETOS COM PROJETO:", projetos)
      dispatch(setListMeusProjetos(result.data))
      dispatch(setListarProjetos(projetos))
      dispatch(setProjeto(projeto))
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}

export const listarMeusProjetosEmpresas = e => {
  const params = paramsApi(e)
  return dispatch => {
    api.get(`/Empresa/ListaEmpresas${params}`)
    .then(result => {
      if (result.data) {
        let empresas = (result.data.usuaempresasrios?result.data.usuaempresasrios:result.data.informacoesEmpresa).map(r=> ({...r, descricaoEmpresa: r.nomeEmpresa}))

        dispatch([
          setFormProjetoLista({name: 'empresas', value: result.data.usuaempresasrios?result.data.usuaempresasrios:result.data.informacoesEmpresa}),
          setFormProjetoLista({name: 'empresasPaginate', value: {...paginateInit(), ...result.data.paginacao?result.data.paginacao:{totalElements: result.data.quantidadeEmpresas}}}),

          setListarEmpresas(empresas),
          setInfoEmpresa(empresas[0])
        ])
      }
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}



export const setListMeuProjetoTab = payload => ({
  type: 'SET_MEUS_PROJETOS_TAB',
  payload
})

export const putListMeuProjetoTab = payload => ({
  type: 'PUT_MEUS_PROJETOS_TAB',
  payload
})

export const removeListMeuProjetoTab = payload => ({
  type: 'REMOVE_MEUS_PROJETOS_TAB',
  payload
})

export const setMeusProjetosLoad = payload => ({
  type: 'SET_MEUS_PROJETO_LOAD',
  payload
})


export const listarMeusProjetosEstabelecimentos = e => {
  const params = paramsApi(e)
  return dispatch => {
    api.get(`/Estabelecimento/ListaEstabelecimentos${params}`)
    .then(result => {
      if (result.data) {
        dispatch([
          setFormProjetoLista({name: 'estabelecimentos', value: result.data.lojas}),
          setFormProjetoLista({name: 'estabelecimentosPaginate', value: {...paginateInit(), ...result.data.paginacao}})
        ])
      }
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetosEstabelecimentos');
    });
  }
}

export const ativarInativaprojeto = (e, nlsPopup) => {
 
  console.log("TESTE NLSPOPUP", nlsPopup[7])
  const urlAcao = e.statusBolean?'Inativaprojeto':'Ativaprojeto'
  return dispatch => {
    dispatch(loading())
    api.put(`/Projeto/${urlAcao}?idProjeto=${e.idProjeto}`)
    .then(result => {
      dispatch(putListMeuProjeto(result.data?.result?result.data?.result:result.data))
      if (urlAcao === 'Inativaprojeto') {
        dispatch(AddAlert('success', nlsPopup.mensagens.inativadocomsucesso));
      } else if (urlAcao === 'Ativaprojeto') {
        dispatch(AddAlert('success', nlsPopup.mensagens.ativadocomsucesso));
      }
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['101'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
          textos: {
            Empresas: data.complemento.empresas,
            Estabelecimentos: data.complemento.estabelecimentos,
            Produtos: data.complemento.produtos,
          }
        }))
      } else {
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const listarMeusProjetosProdutos = e => {
  const params = paramsApi(e)
return dispatch => {
    api.get(`/Produto/ListaProdutos${params}`)
    .then(result => {
      if (result.data) {
        dispatch([
          setFormProjetoLista({name: 'produtos', value: result.data.lista}),
          setFormProjetoLista({name: 'produtosPaginate', value: {...paginateInit(), ...result.data.paginacao}})
        ])
      }
    })
    .catch(err => {
        console.error(err, 'listarMeusProjetos');
    });
  }
}

// const meuProjeto = {gruposProdutos: []}

// export const adicionarMeusProjetosGrupo = e => {
//   console.log(e, 'adicionarMeusProjetosGrupo');
//   return dispatch => {
//     if (e.nome) {
//       dispatch(setFormProjetoLista({name: 'gruposProdutos', value: [...meuProjeto.gruposProdutos, {...e, id: numberAleatori()}]}))
//     }
//   }
// } 

// export const editarMeusProjetosGrupo = e => {
//   console.log(e, 'editarMeusProjetosGrupo');
//   return dispatch => {
//     if (e.nome) {
//       const list = meuProjeto.gruposProdutos.map(g=> Number(g.id) === Number(e.id) ? {...g, ...e} : g)
//       dispatch(setFormProjetoLista({name: 'gruposProdutos', value: list}))
//     }
//   }
// } 

// export const removerMeusProjetosGrupo = e => {
//   console.log(e, 'removerMeusProjetosGrupo');
//   return dispatch => {
//     const list = meuProjeto.gruposProdutos.filter(g=> Number(g.id) !== Number(e.id))
//     dispatch(setFormProjetoLista({name: 'gruposProdutos', value: list}))
//   } 
// }




export const criarProjeto = (e, c, nlsPopup) => {
  const params = {
    "nomeProjeto": e.nome,
    "idSegmento": e.segmento.id,
    "idIcone": Number(e.icone.replace('.png', '')),
    "descricaoLongaProjeto": e.descricao
  }
  return dispatch => {
    dispatch(loading())
    api.post(`/Projeto/CriaProjeto`, params)
    .then(({data}) => {
      if (data?.result) {
        dispatch(setListMeuProjeto(e))
        dispatch(modalClose())
        dispatch(listarMeusProjetos())
        dispatch(verificaCreditoConta())
        if (c && data?.result?.idProjeto) {
          setTimeout(() => {
            history.push(`/meusprojetos/${data?.result?.idProjeto}/configurar/empresas`)
          }, 600);
        }
        dispatch(AddAlert('success', nlsPopup.mensagens.salvarSucesso))
      } else{
        console.error('err', 'criarProjeto');

        //FECHA O POPUP COM OS DADOS
        dispatch(modalClose()) 

        //ABRE O POPUP COM MENSAGEM DE ERRO
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[12]
            show: true, // Garante que o modal será exibido          
          })
        )


        
      }
    })
    .catch(err => {
        console.error(err, 'criarProjeto Catch');
        
        //FECHA O POPUP COM OS DADOS
        dispatch(modalClose())
        //ABRE O POPUP COM MENSAGEM DE ERRO
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[12]
            show: true, // Garante que o modal será exibido          
          })
        )

    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const editarProjeto = (e, nlsPopup) => {
  const params = {
    "idProjeto": e.idProjeto,
    "nomeProjeto": e.nome,
    "idIcone": Number(e.icone.replace('.png', '')),
    "descricaoLongaProjeto": e.descricao
  }
  return dispatch => {
    dispatch(loading())
    api.put(`/Projeto/EditaProjeto`, params)
    .then(result => {
      if (result?.data) {
        const resp = {
          "idProjeto": e.idProjeto,
          "descricao": e.nome,
          "idIcone": e.icone,
          'icone': e.icone, 
          "descricaoLonga": e.descricao
        }
        dispatch(putListMeuProjeto(resp))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', nlsPopup.mensagens.editarSucesso))
      } else{
        console.error('erro', 'editarProjeto');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[12]
            show: true, // Garante que o modal será exibido          
          })
        )
      }
    })
    .catch(err => {
        console.error(err, 'editarProjeto');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[12]
            show: true, // Garante que o modal será exibido          
          })
        )
    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}


export const criarEmpresa = e => {
  const params = {
    "projetoId": e.projetoId,
    "nomeEmpresa": e.nomeEmpresa,
    "urlFacebookEmpresa": e.urlFacebookEmpresa?e.urlFacebookEmpresa:'',
    "urlInstagramEmpresa": e.urlInstagramEmpresa?e.urlInstagramEmpresa:'',
    "urlTwitterEmpresa": e.urlTwitterEmpresa?e.urlTwitterEmpresa:'',
    "urlYoutubeEmpresa": e.urlYoutubeEmpresa?e.urlYoutubeEmpresa:'',
    "urlTiktokEmpresa": e.urlTiktokEmpresa?e.urlTiktokEmpresa:'',
  }
  return dispatch => {
    //console.log("nlsPopup", e.nlsPopup);

    dispatch(loading())

    api.post(`/Empresa/CriaEmpresa`, params)
    .then(({data}) => {
      if (data?.result) {
        // dispatch(setListMeuProjetoTab({name: 'empresas', value: data?.resut, id: 'empresaId'}))
        dispatch(listarMeusProjetosEmpresas({...paginateInit(), idProjeto: e.projetoId}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.criadocomsucesso))
      } else{
        console.error('err', 'criarEmpresa');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'criarEmpresa');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const importarEmpresa = ({ file, nlsPopup, projetoId }) => {
  const formData = new FormData();
  
  formData.append('file', file)
  console.log("VALOR nls\popup[7]", nlsPopup[7])

  return dispatch => {
    dispatch(loading())
    api.post(`/Empresa/CriaEmpresasEmMassa?projetoId=${projetoId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(result => {
      if (result?.data) {
        // dispatch(setListMeuProjetoTab({name: 'empresas', value: {...params, id: idRdm()}, id: 'empresaId'}))
        dispatch(listarMeusProjetosEmpresas({...paginateInit(), idProjeto: projetoId}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', nlsPopup.mensagens.criadocomsucesso))
      } else{
        console.error('err', 'importarEmpresa');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[12]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
      dispatch(modalClose())
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['102'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      } else {
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    })

    .finally(()=> {
      dispatch(loaded())
    })
  }
}





export const editarEmpresa = e => {
  const params = {
    "projetoId": e.projetoId,
    "empresaId": e.empresaId,
    "nomeEmpresa": e.nomeEmpresa,
    "urlFacebookEmpresa": e.urlFacebookEmpresa?e.urlFacebookEmpresa:'',
    "urlInstagramEmpresa": e.urlInstagramEmpresa?e.urlInstagramEmpresa:'',
    "urlTwitterEmpresa": e.urlTwitterEmpresa?e.urlTwitterEmpresa:'',
    "urlYoutubeEmpresa": e.urlYoutubeEmpresa?e.urlYoutubeEmpresa:'',
    "urlTiktokEmpresa": e.urlTiktokEmpresa?e.urlTiktokEmpresa:'',
  }
  return dispatch => {
    api.put(`/Empresa/EditaEmpresa`, params)
    .then(result => {
      if (result?.data) {
        dispatch(listarMeusProjetosEmpresas({...paginateInit(), idProjeto: e.projetoId}))
        // dispatch(putListMeuProjetoTab({name: 'empresas', value: params, id: 'empresaId'}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.editadocomsucesso))
      } else{
        console.error('err', 'editarEmpresa');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'editarEmpresa');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const removerEmpresa = (e, idProjeto, nlsPopup) => {
  const params = e
  return dispatch => {
    api.put(`/Empresa1/AgendaDeleteEmpresas`, params)
    .then(result => {
      if (result?.data) {    
        dispatch(listarMeusProjetosEmpresas({...paginateInit(), idProjeto}))
        dispatch(verificaCreditoConta())
        // e.map(id=> {
        //   dispatch(removeListMeuProjetoTab({id, name: 'empresas'}))
        // })
      } else{
        console.error('err', 'removerEmpresa');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'removerEmpresa');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}


export const criarProduto = e => {
  const params = {
    ...(e.nomeEmpresa.empresaId && { "empresaId": e.nomeEmpresa.empresaId }),
    ...(e.subSegmento.idSubSegmento && { "subSegmentoId": e.subSegmento.idSubSegmento }),
    ...(e.grupoProduto.idGrupoProduto && { "grupoProdutoId": e.grupoProduto.idGrupoProduto }),
    "nomeProduto": e.nomeProduto,
    "urlAmazonProduto": e.urlAmazonProduto,
  }
  return dispatch => {
    dispatch(loading())
    api.post(`/Produto/criaProduto`, params)
    .then(result => {
      if (result?.data) {
        dispatch(listarMeusProjetosProdutos({...paginateInit(), idProjeto: e.projetoId}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.criadocomsucesso))
        // dispatch(setListMeuProjetoTab({name: 'produtos', value: {...params, id: idRdm()}, id: 'idProduto'}))
      } else{
        console.error('err', 'criarProduto');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro) {
        dispatch(modalAlertaInfo({
          ...e.nlsPopup['105'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true, 
        }))
      } else {
        dispatch(modalAlertaInfo({
          ...e.nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const importarProduto = e => {
  const formData = new FormData();
  
  formData.append('file', e.file)

  return dispatch => {
    dispatch(loading())
    api.post(`/Produto/CriaProdutosEmMassa?projetoId=${e.projetoId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(result => {
      if (result?.data) {
        // dispatch(setListMeuProjetoTab({name: 'empresas', value: {...params, id: idRdm()}, id: 'empresaId'}))
        dispatch(listarMeusProjetosProdutos({...paginateInit(), idProjeto: e.projetoId}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.criadocomsucesso))
      } else{
        console.error('err', 'importarProduto');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
      dispatch(modalClose())
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro === 106) {
        dispatch(modalAlertaInfo({
          ...e.nlsPopup['106'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true, 
        }))
      } else if (data?.iderro === 107) {
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup['107'],
            btnAction: () => dispatch(modalAlertaClean()),
            show: true,
            textos: {
              Empresas107: data.complemento,
            }
          })
        );
      } else if (data?.iderro === 108) {
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup['108'],
            btnAction: () => dispatch(modalAlertaClean()),
            show: true,
            textos: {
              Subsegmentos108: data.complemento,
            }
          })
        );
      } else if (data?.iderro === 109) {
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup['109'],
            btnAction: () => dispatch(modalAlertaClean()),
            show: true,
          })
        );
      } else {
        dispatch(modalAlertaInfo({
          ...e.nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const editarProduto = e => {
  const params = {
    ...(e.nomeEmpresa.empresaId && { "empresaId": e.nomeEmpresa.empresaId }),
    ...(e.subSegmento.idSubSegmento && { "subSegmentoId": e.subSegmento.idSubSegmento }),
    ...(e.grupoProduto.idGrupoProduto && { "grupoProdutoId": e.grupoProduto.idGrupoProduto }),
    "idProduto": e.idProduto,
    "nomeProduto": e.nomeProduto,
    "urlAmazonProduto": e.urlAmazonProduto,
  }
  
  return dispatch => {
    api.put(`/Produto/EditaProduto`, params)
    .then(result => {
      if (result?.data) {
        dispatch(listarMeusProjetosProdutos({...paginateInit(), idProjeto: e.projetoId}))
        // dispatch(putListMeuProjetoTab({name: 'produtos', value: params, id: 'idProduto'}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.editadocomsucesso))
      } else{
        console.error('err', 'editarProduto');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'editarProduto');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const removerProduto = (e, idProjeto, nlsPopup) => {
  const params = e
  return dispatch => {
    api.put(`/Produto/AgendaDeleteProdutos`, params)
    .then(result => {
      if (result?.data) {    
        
        dispatch(listarMeusProjetosProdutos({...paginateInit(), idProjeto}))
        dispatch(verificaCreditoConta())
        // e.map(id=> {
        //   dispatch(removeListMeuProjetoTab({id, name: 'empresas'}))
        // })
      } else{
        console.error('err', 'removerProduto');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'removerProduto');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}


export const criarEstabelecimento = e => {
  const params = {
    "empresaId": e.nomeEmpresa.empresaId,
    "grupoEstabelecimentoId": e.grupoEstabelecimentoId?e.grupoEstabelecimentoId:e.grupoEstabelecimento.idGrupoEstabelecimento,
    "nomeEstabelecimento": e.nomeEstabelecimento,
    "ruaAvenidaRodovia": e.ruaAvenidaRodovia,
    "numero": e.numero,
    "complemento": e.complemento,
    "bairro": e.bairro,
    "cidade": e.cidade,
    "estado": e.estado,
    "cep": e.cep,
    "pais": e.pais,
    "coordenadas": e.coordenadas,
    "urlTripadvisorEstabelecimento": e.urlTripadvisorEstabelecimento?e.urlTripadvisorEstabelecimento:e.urlTripAdvisorEstabelecimento,
  }
  return dispatch => {
    dispatch(loading())
    api.post(`/Estabelecimento/criaEstabelecimento`, params)
    .then(result => {
      if (result?.data) {
        
        dispatch(listarMeusProjetosEstabelecimentos({...paginateInit(), idProjeto: e.projetoId}))
        dispatch(verificaCreditoConta())
        // dispatch(listarMeusProjetosEstabelecimentos(e.projetoId))
        // dispatch(setListMeuProjetoTab({name: 'estabelecimentos', value: {...params, id: idRdm()}, id: 'idEstabelecimento'}))
        dispatch(modalClose())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.criadocomsucesso))
      } else{
        console.error('err', 'criarEstabelecimento');
        dispatch(modalClose())
        dispatch(
          
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'criarEstabelecimento');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const importarEstabelecimento = e => {
  const formData = new FormData();
  
  formData.append('file', e.file)

  return dispatch => {
    dispatch(loading())
    api.post(`/Estabelecimento/CriaEstabelecimentosEmMassa?projetoId=${e.projetoId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(result => {
      if (result?.data) {
        // dispatch(setListMeuProjetoTab({name: 'empresas', value: {...params, id: idRdm()}, id: 'empresaId'}))
        dispatch(listarMeusProjetosEstabelecimentos({...paginateInit(), idProjeto: e.projetoId}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.criadocomsucesso))
      } else{
        console.error('err', 'importarEstabelecimento');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
      dispatch(modalClose())
    })
    .catch(({ response: { data } }) => {
      dispatch(modalClose());
      if (data?.iderro === 103) {
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup['103'],
            btnAction: () => dispatch(modalAlertaClean()),
            show: true,
          })
        );
      } else if (data?.iderro === 104) {
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup['104'],
            btnAction: () => dispatch(modalAlertaClean()),
            show: true,
            textos: {
              Empresas104: data.complemento,
            }
          })
        );
      } else {
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup['7'],
            btnAction: () => dispatch(modalAlertaClean()),
            show: true,
          })
        );
      }
    })    

    .finally(()=> {
      dispatch(loaded())
    })
  }
}

export const editarEstabelecimento = e => {
  const params = {
    "idEstabelecimento": e.idEstabelecimento,
    "empresaId": e.empresaId,
    "grupoEstabelecimentoId": e.grupoEstabelecimentoId?e.grupoEstabelecimentoId:e.grupoEstabelecimento.idGrupoEstabelecimento,
    "nomeEstabelecimento": e.nomeEstabelecimento,
    "ruaAvenidaRodovia": e.ruaAvenidaRodovia,
    "numero": e.numero,
    "complemento": e.complemento,
    "bairro": e.bairro,
    "cidade": e.cidade,
    "estado": e.estado,
    "cep": e.cep,
    "pais": e.pais,
    "coordenadas": e.coordenadas,
    "urlTripadvisorEstabelecimento": e.urlTripadvisorEstabelecimento?e.urlTripadvisorEstabelecimento:e.urlTripAdvisorEstabelecimento,
  }
  return dispatch => {
    api.put(`/Estabelecimento/EditaEstabelecimento`, params)
    .then(result => {
      if (result?.data) {
        dispatch(listarMeusProjetosEstabelecimentos({...paginateInit(), idProjeto: e.projetoId}))
        dispatch(verificaCreditoConta())
        // dispatch(listarMeusProjetosEstabelecimentos(e.projetoId))
        // dispatch(putListMeuProjetoTab({name: 'estabelecimentos', value: params, id: 'idEstabelecimento'}))
        dispatch(modalClose())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.editadocomsucesso))
      } else{
        console.error('err', 'editarEstabelecimento');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'editarEstabelecimento');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const removerEstabelecimento = (e, idProjeto, nlsPopup) => {
  const params = e.map(a=> Number(a))
  return dispatch => {
    api.post(`/Estabelecimento/DeletaOuAgendaDeleteEstabelecimentos`, params)
    .then(result => {
      if (result?.data) {   
        dispatch(listarMeusProjetosEstabelecimentos({...paginateInit(), idProjeto}))
        dispatch(verificaCreditoConta())
        // dispatch(listarMeusProjetosEstabelecimentos(projetoId)) 
        // e.map(id=> {
        //   dispatch(removeListMeuProjetoTab({id, name: 'empresas'}))
        // })
      } else{
        console.error('err', 'removerEstabelecimento');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(err => {
        console.error(err, 'removerEstabelecimento');
        dispatch(
          modalAlertaInfo({
            ...nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const listarEstabelecimentosVerificar = e => {
  return dispatch => {
    api.get(`/Estabelecimento/ListaVerificacaoEstabelecimentos?IdEstabelecimento=${e}`)
    .then(result => {
      dispatch(setFormProjetoLista({name: 'estabelcimentosVerificar', value: result.data}))
      dispatch(verificaCreditoConta())
    })
    .catch(err => {
        console.error(err, 'listarEstabelecimentosVerificar');
    });
  }
}

export const estabelecimentosVerificar = e => {
  const params = paramsApi(e)
  return dispatch => {
    api.put(`/Estabelecimento/VerificaEstabelecimento${params}`)
    .then(result => {
      if (result?.data) {   
        dispatch(listarMeusProjetosEstabelecimentos({...paginateInit(), idProjeto: e.idProjetos}))
        dispatch(verificaCreditoConta())
        dispatch(AddAlert('success', e.nlsPopup.mensagens.verificadocomsucesso))
      } else{
        console.error('erro', 'estabelecimentosVerificar');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(erro => {
        console.error(erro, 'estabelecimentosVerificar');
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}





export const listarGruposProdutos = e => {
  return dispatch => {
    api.get(`/Produto/ListaGruposProdutos?idProjeto=${e}`)
    .then(result => {
      dispatch(setFormProjetoLista({name: 'gruposProdutos', value: result.data}))

      const respGrupos = result.data.map(g=> ({...g, id: g.idGrupoProduto, name: g.nomeGrupoProduto}))
      dispatch(setListarGrupos(respGrupos))
      dispatch(setInfoGrupo(respGrupos[0]))
    })
    .catch(err => {
        console.error(err, 'listarGruposProdutos');
    });
  }
}

export const criarGrupoProduto = e => {
  const params = {
    "projetoId": e.projetoId,
    "nomeGrupoProduto": e.nomeGrupoProduto
  }
  return dispatch => {
    api.post(`/Produto/CriaGrupoProduto`, params)
    .then(result => {
      if (result.data) {
        dispatch(listarGruposProdutos(e.projetoId))
      } else {
        console.error('erro', 'criarGrupoProduto');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(erro => {
        console.error(erro, 'criarGrupoProduto');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const removerGrupoProduto = (e, nlsPopup) => {
  console.log("NLSPOPUP EM GRUPO", nlsPopup)
  return dispatch => {
    api.delete(`/Produto/DeletaGrupoProduto?grupoProdutoID=${e.idGrupoProduto}`)
    .then(result => {
      if (result.data) {
        dispatch(listarGruposProdutos(e.idProjeto))
      } else {
        console.error('erro', 'removerGrupoProduto');
      }
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro === 110) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['110'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      } 
    });
  }
}

export const listarGruposEstabelecimentos = e => {
  return dispatch => {
    api.get(`/Estabelecimento/ListaGruposEstabelecimentos?idProjeto=${e}`)
    .then(result => {
      dispatch(setFormProjetoLista({name: 'gruposEstabelecimentos', value: result.data}))

      const respGrupos = result.data.map(g=> ({...g, id: g.idGrupoEstabelecimento, name: g.nomeGrupoEstabelecimento}))
      dispatch(setListarGrupos(respGrupos))
      dispatch(setInfoGrupo(respGrupos[0]))
    })
    .catch(err => {
      console.error(err, 'listarGruposEstabelecimentos');
    });
  }
}

export const criarGrupoEstabelecimento = e => {
  const params = {
    "projetoId": e.projetoId,
    "nomeGrupoEstabelecimento": e.nome,
    "nlsPopup": e.nlsPopup
    
  }
  return dispatch => {
    api.post(`/Estabelecimento/CriaGrupoEstabelecimento`, params)
    .then(result => {
      if (result.data) {
        dispatch(listarGruposEstabelecimentos(e.projetoId))
      } else {
        console.error('erro', 'criarGrupoEstabelecimento');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

      }
    })
    .catch(erro => {
        console.error(erro, 'criarGrupoEstabelecimento');
        dispatch(modalClose())
        dispatch(
          modalAlertaInfo({
            ...e.nlsPopup[7], // Inclui o conteúdo de nlsPopup[7]
            show: true, // Garante que o modal será exibido          
          })
        )

    });
  }
}

export const removerGrupoEstabelecimento = (e, nlsPopup) => {
  return dispatch => {
    api.delete(`/Estabelecimento/DeletaGrupoEstabelecimento?grupoEstabelecimentoID=${e.idGrupoEstabelecimento}`)
    .then(result => {
      if (result.data) {
        dispatch(listarGruposEstabelecimentos(e.idProjeto))
      } else {
        console.error('erro', 'removerGrupoEstabelecimento');
      }
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro === 111) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['111'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      } 
    });
  }
}

export const listarSubsegmentos = e => {
  const params = paramsApi(e)
  return dispatch => {
    api.get(`/Produto/ListaSubSegmentosPorProjeto${params}`)
    .then(result => {
      dispatch(setFormProjetoLista({name: 'subsegmentos', value: result.data}))
    })
    .catch(err => {
        console.error(err, 'listarSubsegmentos');
    });
  }
}