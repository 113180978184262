import React, { useState } from 'react'
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import { useEffect } from 'react'
import { cleanFormProjeto, listarMeusProjetos, listarSubsegmentos, setFormProjetoLista } from '../redux/meusProjetosActions'
import { Button, IcoHelpCircle, IcoSearch, Input, Popover, Select } from '../../../components'
import { IF, history } from '../../../helpers';
import Projeto from './projeto/projeto';
import Empresas from './empresas/empresas';
import Estabelecimentos from './estabelecimentos/estabelecimentos';
import Produtos from './produtos/produtos';

export default () => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const location = useLocation();
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  console.error(sistema.idioma, 'sistema.idioma em configurar')
  const { listMeusProjetos, paginate, meuProjeto } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({projeto: {}, search:''})
  const projetosPermitidos = listMeusProjetos.filter(e=> e.permissaoEditarProjeto && e.situacao !== 'I')
  const selectedProjectHas = projetosPermitidos.find(e=> String(e.idProjeto) === String(idProjetos))
  
  useEffect(()=> {
    if (!idPasso) {
      history.push(`/meusprojetos/${idProjetos}/configurar/${listTab[0].id}`)
    }
    setForm({...form, search:''})
  }, [idPasso])

  useEffect(()=> {
    dispatch(cleanFormProjeto())
    // dispatch(listarMeusProjetos())
  }, [])

  useEffect(()=> {
    if (!selectedProjectHas?.idProjeto && projetosPermitidos.length) {
      history.push(`/meusprojetos/${projetosPermitidos[0].idProjeto}/configurar/${listTab[0].id}`)
    }
  }, [selectedProjectHas, projetosPermitidos])

  useEffect(()=> {
    setForm({...form, projeto: selectedProjectHas?selectedProjectHas:{}})
    dispatch(setFormProjetoLista({name: 'projeto', value: selectedProjectHas?selectedProjectHas:{}}))
  }, [selectedProjectHas])

  useEffect(()=> {
    const query = new URLSearchParams(location.search);
    const searchTerm = query.get(idPasso);
    if (searchTerm) {
      setForm({...form, search:searchTerm})
    }
  }, [history.location])

  const listTab = [
    {
      id: 'projeto',
    },
    ...meuProjeto.projeto.temMenuEmpresa ? [{
      id: 'empresas',
    }]:[],
    ...meuProjeto.projeto.temMenuEstabelecimento ? [{
      id: 'estabelecimentos',
    }]:[],
    ...meuProjeto.projeto.temMenuProduto ? [{
      id: 'produtos',
    }]:[],
  ]
  
  const handleChange = e => {
    console.log(e, 'handleChange');
    // dispatch(setFormProjeto({name: e.name, value: e.value, tipo: 'configurar'}))
  }

  const contentPage = e =>{
    switch (e) {
      case 'projeto':
        return <Projeto />;
      case 'empresas':
        return <Empresas />;
      case 'estabelecimentos':
        return <Estabelecimentos />;
      case 'produtos':
        return <Produtos />;
      default:
        return <div></div>;
    }
  }
  
  const handleSearch = (e, t) => {
    let val = ''
    if (e) {
      val = `?${t}=${e}`
    }
    history.push({
      search: val
    });
  }

  return (
    <div className='box-meus-projetos-configurar'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          <h3>{nls.configurar.title} </h3>
          <div className='actions-tab'>
            {
              listTab.map(e=> {
                return <Button
                  key={e.id}
                  color={idPasso === String(e.id)?'primary':'default'}
                  variant='underline'
                  onClick={()=> history.push(`/meusprojetos/${idProjetos}/configurar/${e.id}`)}
                >{nls.configurar[e.id]}</Button>
              })
            }
          </div>
        </div>
        <div className='box-meus-projetos-configurar-search'>
          <Select
            name='projeto'
            label=''
            options={projetosPermitidos}
            selected={form.projeto}
            action={(e)=> history.push(`/meusprojetos/${e.value.idProjeto}/configurar/${listTab[0].id}`)}
            selectedItem={false}
            textCustom={nls.select}
            optionLabel='descricao'
            optionValue='idProjeto'
          />
          <IF se={idPasso !== 'projeto'}>
            <Input
              name='search'
              label=''
              value={form.search}
              placeholder={nls.configurar?.[`SPH${idPasso}`]}
              action={(e) => setForm({...form, [e.name]: e.value})}
              left={<Button
                type='link'
                color='default'
                onClick={()=> handleSearch(form.search, idPasso)}
              ><IcoSearch /></Button>}
            />
          </IF>
        </div>
        {
          idProjetos ? <div>
            {contentPage(idPasso)}
          </div>:null
        }
      </div>
    </div>
  )
}
