import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoPaste, IcoUpload, Input, InputFile, Modal } from '../../../../components'
import { history, numberAleatori } from '../../../../helpers'
import { criarEmpresa, editarEmpresa, putListMeuProjetoTab, setListMeuProjetoTab } from '../../redux/meusProjetosActions'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'


export default ({data}) => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)

  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [120];
  const idarray1 = [121];

  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    let formInit = { nomeEmpresa: '', logo: '' }
    listRS.map(e=>{
      formInit = {...formInit, [e.id]: ''}
    })
    setForm({...form, ...formInit, ...data})
  }, [data])

  const listRS = [
    {
      id: 'urlFacebookEmpresa',
      label: 'Facebook',
      ph: 'www.facebook.com',
    },
    {
      id: 'urlInstagramEmpresa',
      label: 'Instagram',
      ph: 'www.instagram.com',
    },
    {
      id: 'urlTwitterEmpresa',
      label: 'Twitter',
      ph: 'www.twitter.com',
    },
    {
      id: 'urlYoutubeEmpresa',
      label: 'Youtube',
      ph: 'www.youtube.com',
    },
    {
      id: 'urlTiktokEmpresa',
      label: 'Tiktok',
      ph: 'www.tiktok.com',
    }
  ]

  const handleChange = e => {
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    setForm({...form, [e.name]: e.files})
  } 

   
  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom
    if (data?.empresaId) {
      dispatch(editarEmpresa({...e, projetoId: idProjetos, nlsPopup}))    
    } else {
      dispatch(criarEmpresa({...e, projetoId: idProjetos, nlsPopup}))
    }
    handleCloseModal()
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  }  
  const handleColar = e => {
    navigator.clipboard.readText().then((clipText) => {
      setForm({...form, [e]: clipText})
    });
  }  

  return (
    <Modal
    title={
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {data?.empresaId
          ? nls.configurar?.editEmpresaTitle
          : nls.configurar?.addEmpresaTitle}
        <PopoverCentralAjuda 
          nlsContexto={nlsContexto} 
          idarray={data?.empresaId ? idarray1 : idarray} 
        />
      </div>
    }

      size='medium'
      open={statusModal === 'criarEmpresaMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
      actions={<>
        {data?.empresaId? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls.configurar?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(form)}
        >{nls.configurar?.btnFinalizarSalvar}</Button>
      </>}
    >
      <div className='modal-meuProjeto-empresas-add'>
        <h5>{nls.configurar?.subTitleDetalhes}</h5>
        <div>
          <Input
            name='nomeEmpresa'
            label={nls.configurar?.formNome}
            value={form?.nomeEmpresa}
            placeholder={nls.configurar?.formNomePH}
            action={(e) => handleChange(e)}
            right={<>
              <span className='btn-colar' onClick={()=> handleColar('nomeEmpresa')}><IcoPaste /> {nls.configurar?.btnColar}</span>
            </>}
            required={{
              erro,
              message: ''
            }}
          />
          {/* <InputFile
            name='logo'
            label={nls.configurar?.formLogo}
            value={form?.logo}
            placeholder={<span>{nls.configurar?.formLogoPH} <IcoUpload /></span> }
            action={(e) => handleChangeFile(e)}
          /> */}
        </div>
        <h5>{nls.configurar?.subTitleRedesSociais}</h5>
        <div className='colflex col2'>
          {listRS.map(e=>{
            return <Input
              key={e.id}
              name={e.id}
              label={e.label}
              value={form?.[e.id]}
              placeholder={e.ph}
              action={(e) => handleChange(e)}
              disabled={data?.empresaId && data?.[e.id]?true:false}
              right={<>
                <span className='btn-colar' onClick={()=> !(data?.empresaId && data)?.[e.id] ? handleColar(e.id):null}><IcoPaste /> {nls.configurar?.btnColar}</span>
              </>}
            />
          })}
        </div>
      </div>
    </Modal>
  )
}
