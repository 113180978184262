import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../layout/redux/layoutActions'
import { Button, Modal } from '../../components'
import AddContent from './addContent'
import { cleanUsuario, criarUsuario, editarUsuario } from './redux/usuariosActions'


export default () => {
  const dispatch = useDispatch()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { usuario } = useSelector(state => state.usuariosState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)


  useEffect(()=> {

  }, [])

  const handlerCriar = e => {
    delete e.actions
    delete e.checkboxCustom
    const hasPermission = e.permissoes.filter(f=> f.podeVisualizar || f.podeEditar)
    
    if (hasPermission.length) {
      if (usuario?.id) {
        dispatch(editarUsuario(e, nlsPopup))    
      } else {
        dispatch(criarUsuario(e, nlsPopup))
      }
      handleCloseModal()
    }
  }  

  const handleCloseModal = e => {
    dispatch([
      cleanUsuario(),
      modalClose()
    ]) 
  }  

  return (
    <Modal
      title={usuario?.id?nls?.editTitle:nls?.addTitle}
      size='medium'
      open={statusModal === 'criarProdutoMeuProjeto' ? true : false}
      close={() => handleCloseModal()}
      closeText={nls?.btnFechar}
      actions={<>
        {usuario?.id? <Button
          color='default'
          onClick={()=> handleCloseModal()}
        >{nls?.btnCancelar}</Button>
        :null}
        <Button
          color='primary'
          onClick={()=> handlerCriar(usuario)}
        >{nls?.btnFinalizarSalvar}</Button>
      </>}
    >
     <AddContent />
    </Modal>
  )
}
