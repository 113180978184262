import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import Projetos from '../../projetos/projetos';
import { getIp } from '../../../layout/redux/layoutActions';


export default () => {
  const dispatch = useDispatch()
  // const { sistema, usuario } = useSelector(state => state.layoutState)
  // const nls = require(`./nls/${sistema.idioma}.json`)
  // const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)
  // const { projetos, listas, paginate } = useSelector(state=> state.projetosState)
  useEffect(()=> {
    dispatch(getIp())
  }, [])

  return (
    <Projetos />
  )
}
