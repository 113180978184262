import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../../components'
import './indicadores.scss'
import Filter from './filter/filter'
import Grafico from './grafico'
import { setInfoEmpresa, setInfoGrupo, setProjeto } from '../../../layout/redux/layoutActions'
import { carregarGrafico, filterHandle } from './redux/indicadoresActions'

export default () => {
  const dispatch = useDispatch()
  const { grupos, grupo, filters, empresas, empresa, sistema } = useSelector(state=> state.layoutState)
  const { projeto, projetos, totalAvaliacao, filtersProvisorio } = useSelector(state=> state.indicadoresState)

  const nls = require(`../indicadores/nls/${sistema.idioma}.json`) 

  useEffect(()=> {
    if (projeto?.id) {
      dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, grupo, params: filters, typeSelect: filters.typeSelect, filtersProvisorio, todascategoriasprojeto: nls.todascategoriasprojeto, idioma: sistema.idiomaIdValue}))
    }
  }, [projeto, grupo, sistema.idiomaIdValue])

  const handleFormProjeto = (e) => {
    dispatch(setProjeto(e.value))
  }
  const handleGrupo = (e) => {
    dispatch(setInfoGrupo(e.value))
  }

  const handleFormFilterEmpresa = (e) => {
    dispatch(setInfoEmpresa(e.value))
  }

  return (
    <div className='box-indicadores'>
      <div className='box-indicadores-topinfo'>
        <div>
          <div className='box-indicadores-topinfo-select'>
            <Select 
              name='projeto'
              label={nls.projeto}
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormProjeto(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          
          {/*TIREI EMPRESA DA VERSÃO ANTERIOR: NÃO ERA USADO*/}

          <div className='box-indicadores-topinfo-select'>
            <Select 
              name='grupo'
              label={nls.grupo}
              options={grupos}
              selected={grupo}
              action={(e)=> handleGrupo(e)}
              selectedItem={false}
            />
          </div>
        </div>
        <div className='box-indicadores-topinfo-avaliacao'>
            <label>{nls.numerodeavaliacoes}</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      {/* <span className='box-indicadores-infoindicadores'>Indicadores de fragilidade da concorrência: <strong>40</strong></span> */}
      <Grafico />
    </div>
  )
}
