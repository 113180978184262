import React, { useState } from 'react'
import { useParams, useLocation  } from "react-router-dom";
import { useDispatch } from 'react-redux'

import axios from 'axios';
import { gerarLista } from './centralAjuda';

import apiV3 from '../../../helpers/apiV3';
import { paramsApi } from '../../../helpers';






export const getInfoCentralAjuda = payload => ({
  type: 'GET_INFO_CENTRAL_AJUDA',
  payload
})

export const setOpenCentralAjuda = payload => ({
  type: 'SET_OPEN_CENTRAL_AJUDA',
  payload
})

export const setCloseCentralAjuda = payload => ({
  type: 'SET_OPEN_CENTRAL_AJUDA',
  payload: false
})

//export const listarCentralAjuda = e => {
//  return dispatch => {
//    dispatch(getInfoCentralAjuda(gerarLista()))
//  }
//}

export const listarCentralAjuda = (ids = null, nls) => {
  
  return (dispatch) => {
    dispatch(getInfoCentralAjuda(gerarLista(ids, nls)));
  };
};


export const setSuporteCentralAjuda = payload => ({
  type: 'SET_SUPORTE_CENTRAL_AJUDA',
  payload
})

export const cleanSuporteCentralAjuda = payload => ({
  type: 'CLEAN_SUPORTE_CENTRAL_AJUDA',
  payload
})


export const handlerEnviarSolicitacao = async (suporte, emailuser) => {
 
  let params = paramsApi({
    emailDestinatario: emailuser,
    assunto: "Solicitação de Suporte Yellow Tokens",
    conteudoSolicitacao: suporte?.mensagem || null,
    linkSolicitacao: suporte?.link || null,
    privateSolicitacao: suporte?.privado ? "true" : "false"

  })

  // Chamar a API para enviar email 
  try {
    const response = await apiV3.get(`/Email/EnviarSolicitacao${params}`);
    //console.log('Solicitação enviada com sucesso:', response.data);
    //alert('Solicitação enviada com sucesso!');
    return true; // Sucesso
  } catch (error) {
    //alert('Erro ao enviar solicitação. Tente novamente.');
    return false; // Falha
  }
};

