import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { modalClose, modalOpen } from '../../../../layout/redux/layoutActions'
import { Button, IcoAdd, IcoHelpCircle, IcoImportar, IcoPaste, IcoUpload, Input, InputFile, Modal } from '../../../../components'
import { downloadArchive, history, numberAleatori } from '../../../../helpers'
import { importarEstabelecimento, setFormProjeto, setListMeuProjeto } from '../../redux/meusProjetosActions'
import { PopoverCentralAjuda } from '../../../../components/popovercentralajuda/popovercentralajuda'


export default ({data}) => {
  const dispatch = useDispatch()
  const {idProjetos, idPasso} = useParams()
  const { sistema, statusModal } = useSelector(state => state.layoutState)
  const { meuProjeto } = useSelector(state => state.meusProjetosState)
  const nls = require(`../../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../../layout/nlsPopup/${sistema.idioma}.json`)

  const nlsContexto = require(`../../../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [126];


  const [erro, setErro] = useState({})  
  const [form, setForm] = useState({})

  useEffect(()=> {
    let formInit = { nome: '', logo: '' }
    listRS.map(e=>{
      formInit = {...formInit, [e.id]: ''}
    })
    setForm({...form, ...formInit, ...data})
  }, [data])

  const listRS = [
    {
      id: 'facebook',
      label: 'Facebook',
      ph: 'www.facebook.com',
    },
    {
      id: 'instagram',
      label: 'Instagram',
      ph: 'www.instagram.com',
    },
    {
      id: 'twitter',
      label: 'Twitter',
      ph: 'www.twitter.com',
    },
    {
      id: 'youtube',
      label: 'Youtube',
      ph: 'www.youtube.com',
    },
    {
      id: 'tiktok',
      label: 'Tiktok',
      ph: 'www.tiktok.com',
    }
  ]

  const handleChange = e => {
    setForm({...form, [e.name]: e.value})
  } 

  const handleChangeFile = e => {
    setForm({...form, [e.name]: e.files})
  } 

  const handlerCriar = e => {
    dispatch(setListMeuProjeto({...e, id: numberAleatori(), status: true, licencaData: new Date().toISOString()}))
  }  

  const handleCloseModal = e => {
    dispatch(modalClose()) 
  } 

  const validar = e => {
    // navigator.clipboard.readText().then((clipText) => {
    //   setForm({...form, [e]: clipText})
    // });
    readURL(e.target.files)
  }  
  
  const readURL = (input) => {
    if (input.length) {
      dispatch(importarEstabelecimento({projetoId: idProjetos, file: input[0], nlsPopup}))
    }
  }

  const modelosTipo = {
    'pt-BR': {
      nome: 'Modelo-Importacao-Estabelecimentos',
      tipos: ['csv', 'xlsx']
    },
    'esp': {
      nome: 'Modelo-Importacion-Establecimientos',
      tipos: ['csv', 'xlsx']
    },
    'en-US': {
      nome: 'Establishments-Import-Framework',
      tipos: ['csv', 'xlsx']
    },
  }
  
  const handleBaixarModelos = (e) => {
    const modelo = modelosTipo[sistema.idioma]
    modelo.tipos?.map(extension=> {
      const archive = require(`./modelos/${sistema.idioma}/${modelo.nome}.${extension}`)
      downloadArchive({archive, name: modelo.nome, extension})
    })
  }

  return (
    <Modal
    title={
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {nls.configurar?.importEstabelecimentoTitle}
        <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
      </div>
    }
      size='medium'
      open={statusModal === 'importarEstabelecimentoMeuProjeto' ? true : false}
      close={() => dispatch(modalClose())}
      closeText={nls.criarProjeto?.btnFechar}
    >
      <div className='modal-meuProjeto-importar'>
        <div className='filearchive'>
            <input
              type={'file'}
              name={'importarestabelecimento'}
              file={''}
              accept=".csv, .xlsx, .xls"
              onChange={(e) => validar(e)}
            />
          <div>
            {form.image?.filesUrl?<img src={form.image.filesUrl} />:<IcoImportar />}
            <span>{form.image?.name?form.image?.name:''}</span>
            <Button
              color='primary'
              onClick={()=> handlerCriar(meuProjeto.projeto)}
            ><IcoUpload /> {nls.configurar?.btnImportarArquivo}</Button>
          </div>
        </div>
        <div>
          <Button
            type='link'
            color='info'
            onClick={()=> handleBaixarModelos(meuProjeto.projeto)}
          >{nls.configurar?.btnImportarModelo}</Button>
        </div>
        </div>
    </Modal>
  )
}
