import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, IcoArchive, IcoDownload, PopoverCentralAjuda } from '../../../components';
import { CustomLabel, CustomTooltip, RenderLegend, colors, graficoOptions } from './graficoOptions';
import { Bar, BarChart, Brush, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BarCustom, CustomTooltipCustom, PageLoadView, RenderLegendCustom, convertChart } from '../../../helpers';

export default ({ nlsContexto, labelgraf, nls }) => {
  const dispatch = useDispatch()
  const { projeto, grafico, graficoLoad } = useSelector(state=> state.rankingcsatState)
  const { titlePage } = useSelector(state=> state.layoutState)
  const [download, setDownload] = useState(false)

  const idarray = [206];


  const handleDownload = async (e) => {
    setDownload(true)
    const chartdownload = await convertChart({id: 'graficos-chart-box', titlePage})
    setDownload(chartdownload)
  }



  return (
    <>
      <div id='graficos-chart-box' className={`box-rankingcsat-graficos box-content ${download?'graficos-download':''}`}>
        <div className='graficos-chart-box-action'>
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />         
          {!graficoLoad?<Button type='btn circle' color='primary' variant='outlined' onClick={()=> handleDownload()} title='Download gráfico'><IcoDownload /></Button>:null}
        </div>
        <div id='graficos-chart' className="box-rankingcsat-graficos-chart">
          <h3>{titlePage}</h3>
          <PageLoadView
           load={graficoLoad} 
           data={grafico.data}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={graficoOptions.BarChart.width}
                height={graficoOptions.BarChart.height}
                data={grafico.data}
                margin={graficoOptions.BarChart.margin}
              >
                <CartesianGrid
                  stroke={graficoOptions.CartesianGrid.stroke} 
                  vertical={graficoOptions.CartesianGrid.vertical} 
                />
                {
                  grafico.data && !(grafico.data.length <= graficoOptions.Brush.endIndex)?
                  <Brush
                    dataKey={graficoOptions.Brush.dataKey} 
                    height={graficoOptions.Brush.height} 
                    stroke={graficoOptions.Brush.stroke} 
                    startIndex={graficoOptions.Brush.startIndex}
                    endIndex={graficoOptions.Brush.endIndex}
                  />
                  :null
                }
                <XAxis 
                  dataKey={graficoOptions.XAxis.dataKey} 
                  stroke={graficoOptions.XAxis.stroke} 
                  axisLine={graficoOptions.XAxis.axisLine} 
                  tickLine={graficoOptions.XAxis.tickLine} 
                  fontSize={graficoOptions.XAxis.fontSize} 
                />
                <YAxis 
                  unit={graficoOptions.YAxis.unit} 
                  stroke={graficoOptions.YAxis.stroke} 
                  axisLine={graficoOptions.YAxis.axisLine} 
                  tickLine={graficoOptions.YAxis.tickLine} 
                  fontSize={graficoOptions.YAxis.fontSize} 
                />
                {/* <Tooltip content={<CustomTooltip labels={grafico.labels} />} />
                <Legend content={<RenderLegend  labels={grafico.labels} />} /> */}
                {/* {
                  grafico.labels?.map((label, i)=> <Bar 
                    key={`bar-${label.type}-${i}`}
                    dataKey={label.type} 
                    fill={label.color} 
                    radius={graficoOptions.Bar.radius} 
                    label={<CustomLabel />}
                  />)
                } */}
                
                
                <Tooltip content={<CustomTooltipCustom labels={grafico.labels} labelgraf={labelgraf} />} />
                <Legend content={<RenderLegendCustom  labels={grafico.labels} />} />

                <Bar
                  dataKey="Atual"
                  fill="Atual"
                  radius={graficoOptions.Bar.radius} 
                  label={<CustomLabel />}
                  shape={<BarCustom />}
                />
                <Bar
                  dataKey="Anterior"
                  fill="Anterior"
                  radius={graficoOptions.Bar.radius} 
                  label={<CustomLabel />}
                  shape={<BarCustom />}
                />
              </BarChart>
            </ResponsiveContainer>
          </PageLoadView>
        </div>
        <div className='box-rankingcsat-graficos-chart-info'>
          <span><strong>Média: </strong> {grafico.media}</span>
          <span><strong>Desvio padrão: </strong> {grafico.desvio}</span>
        </div>
      </div>
      {
        grafico.analiseGrafica?
        <div className='box-rankingcsat-analise-grafica box-content'>
          <h3><IcoArchive/>  {nls.analise}</h3>
          <div dangerouslySetInnerHTML={{__html: grafico.analiseGrafica}}></div>
        </div>
        :null
      }
    </>
  )
}
