import React, { useEffect, useState } from 'react' //aqui com useeffect
import { useDispatch, useSelector } from 'react-redux'
import { IcoCicle, RadioButton, Select } from '../../../../components'
import { putFiltersProvisorio } from '../redux/csatclienteActions'

export default () => {
  const dispatch = useDispatch()
  //aqui comm sistema
  const { ciclos, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio: {ciclo, compararCiclos} } = useSelector(state=> state.csatclienteState)

  //aqui as 3 linhas
  const [ciclo1, setCiclo1] = useState({})
  const [ciclos1, setCiclos1] = useState([])
  const nls = require(`../nls/${sistema.idioma}.json`)

  



  const handleFormFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }

 
  
  /* NÃO PRECISA MAIS...ERA PARA TRADUZIR O CONTEÚDO DO COMBO, AGORA VEM TRADUZIDO DO BACK

  //aqui com useeffect
  useEffect(()=> {
   init()
 }, [])

  const init = () => {
    //console.log("Valor inicial de ciclo:", ciclo); // Exibe o valor de ciclo no console
   const data = ciclo?.anoMesCiclo?.split('-') 
   if (data) {
     const mes = nls.nomesMeses.find(nm=> Number(nm.id) === Number(data[1]))  
     const ciclo1Result = {...ciclo, mesAno:`${mes.name}-${data[0]}`, anoMes:`${data[0]}-${data[1]}`, ano: data[0], mes: data[1]}
     //console.log("Valor de ciclo1Result:", ciclo1Result); 
     //setCiclo1(ciclo1Result)
     //Linha de baixo é necessária porque a primeira vez ciclo vem sem valor correto do idioma...
     dispatch(putFiltersProvisorio({ name: 'ciclo', value: ciclo1Result }))  
   }
 
   const ciclos1Result = ciclos.map(ciclolido => {
     const data = ciclolido.anoMesCiclo.split('-');
     const mes = nls.nomesMeses.find(nm => Number(nm.id) === Number(data[1])); 
     return {
       ...ciclolido,
       mesAno: `${mes.name}-${data[0]}`,
       anoMes: `${data[0]}-${data[1]}`,
       ano: data[0],
       mes: data[1]
     };
   });
   setCiclos1(ciclos1Result)
 }
*/


 const desativar = nls.desativar
 const ativar = nls.ativar

  const compararCiclosList = [
   {
      id: 'desativar',
      name: desativar,
      //name: 'Desativar',
   },
   {
      id: 'ativar',
      name: ativar,
   }
  ]
  
  

  return (
   <div className='box-csatcliente-search csatcliente-search-ciclo'>
    
      <Select
         name='ciclo'
         label={nls.ciclo}
         options={ciclos}  
         selected={ciclo}  
         
         action={(e)=> handleFormFilter(e)}
         selectedItem={false}
         labelCustom={(e)=> <><IcoCicle /> {e.mesAno}</> }
         optionLabel='mesAno'
         optionValue='anoMes'
      />
      <RadioButton
         name='compararCiclos'
         label={nls.compararciclos}
         options={compararCiclosList}
         checked={compararCiclos?compararCiclos:{}}
         action={(e)=> handleFormFilter(e)}
      />
   </div>
  )
}
