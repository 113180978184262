import { dateCustom } from '../../../../components';
import { graficoCustom, numberFormat, numberZero, paramsApi } from '../../../../helpers';
import api from '../../../../helpers/api';
import apiOld from '../../../../helpers/apiOld';
import { setFiltered } from '../../../../layout/redux/layoutActions';

export const setTotalAvaliacao = payload => ({
  type: 'INFO_GRAFICO_RANKINGNPS_TOTAL_AVALIACAO',
  payload
})


export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_RANKINGNPS',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_RANKINGNPS',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_RANKINGNPS',
})


export const setGrafico = payload => ({
  type: 'INFO_GRAFICO_RANKINGNPS',
  payload
})

export const setGraficoLoad = payload => ({
  type: 'LOAD_GRAFICO_RANKINGNPS',
  payload
})
// "listInformacoesCiclo": [
//   {
//       "lojaID": 5,
//       "nomeLoja": "Assaí Bauru",
//       "qtdGeral": 49,
//       "notaAltaGeral": 37,
//       "notaBaixaGeral": 7,
//       "npsGeral": "61.22"
//   },
//   {
//       "lojaID": 6,
//       "nomeLoja": "Tauste Rio Branco",
//       "qtdGeral": 30,
//       "notaAltaGeral": 23,
//       "notaBaixaGeral": 6,
//       "npsGeral": "56.67"
//   }
// ],
// "listInformacoesComparacao": [
//   {
//       "lojaID": 5,
//       "nomeLoja": "Assaí Bauru",
//       "qtdGeral": 0,
//       "notaAltaGeral": 0,
//       "notaBaixaGeral": 0,
//       "npsGeral": null
//   },


export const carregarGrafico = ({projetoID, grupo, params, typeSelect, idioma, labelgraf}) => {
  
  return dispatch => {
    dispatch(setGraficoLoad(true))
    let paramsUrl = paramsApi({
      ...params, 
      projetoId: projetoID,
      Grupo: grupo.name,
      idioma
    })
    apiOld.get(`/front/GetRankingNps${paramsUrl}`)
    .then(result => {
      const resultado = graficoCustom(result.data, '', true, labelgraf )
      dispatch([
        setGrafico(resultado),
        setTotalAvaliacao(result.data.totalAvaliacoes),
        setGraficoLoad(false)
      ])
    })
    .catch(err => {
      console.error(err);
      dispatch([
        setGrafico([]),
        setTotalAvaliacao(0),
        setGraficoLoad(false)
      ])
    });
  }
}
// {typeSelect: 'Ciclo', MesCliclo: '2023-01', Comparacao: false}
export const filterHandle = ({projetoID, grupo, params, typeSelect, filtersProvisorio, idioma, labelgraf}) => {
  console.error({projetoID, grupo, params, typeSelect, filtersProvisorio}, 'filterHandle');
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params?.ciclo : filtersProvisorio?.ciclo}
  return dispatch => {
    let paramsFilter = filtersCustom({params: paramsCustom, typeSelect})
    dispatch(setGraficoLoad(true))
    dispatch([
      setFiltered(paramsCustom),
      carregarGrafico({projetoID, grupo, params: paramsFilter, typeSelect, idioma, labelgraf})
    ])
  }
}

export const filtersCustom = ({params, typeSelect}) => {
  let paramsFilter = {}
  if (typeSelect?.id === 'Ciclo') {
    if (params?.ciclo?.mesAno) {
      paramsFilter = {
        comparacao: params.compararCiclos.id === 'ativar'?true:false,
        intervalo: `${params.ciclo.ano}-${params.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(params.dataInicial).data} ${dateCustom(params.dataFinal).data}`,
    }
  }
  return paramsFilter
}