import React from 'react';
import { useDispatch } from 'react-redux';
import { listarCentralAjuda } from '../../modules/centralAjuda/redux/centralAjudaActions';
import { modalOpen } from '../../layout/redux/layoutActions';
import { IcoHelpCircle } from '../icon/icon';
import { setOpenCentralAjuda } from '../../modules/centralAjuda/redux/centralAjudaActions'

export const PopoverCentralAjuda = ({ 
  actionText = <small><IcoHelpCircle /></small>,
  nlsContexto, 
  idarray
}) => {
  const dispatch = useDispatch();

  //const nls = require(`./nlsteste.json`)

  const handleOpenCentralAjuda = () => {
    // IDs padrão para abrir no Central Ajuda
    const defaultIds = [100];

    // Abre o modal do Central Ajuda com os IDs padrão
    dispatch(setOpenCentralAjuda(true))
    dispatch(listarCentralAjuda(idarray, nlsContexto));
    //dispatch(listarCentralAjuda(defaultIds,nls));
    
  };

  return (
    <div className="box-popover-central-ajuda">
      <div 
        className="box-popover-central-ajuda-head"
        onClick={handleOpenCentralAjuda}
        style={{ cursor: 'pointer' }}
      >
        {actionText}
      </div>
    </div>
  );
};
