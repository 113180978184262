import React, { useEffect, useState } from 'react';
import { Paginate } from '../pagination/pagination';
import { Select } from '../form/form';
import { numberAleatori } from '../../helpers';

export const perPageList = [
  { id: '7' },
  { id: '20' },
  { id: '50' }
]

export const nlsDefault = {
  paginas: `{{Id}} / Páginas`,
  registro: `Registro`,
  registros: `Registros`,
  noData: `Sem informação`,
}



export const List = ({
  header,
  data,
  semdadosparaexibir,
  listCustom = () => null,
  sort={},
  noData = 'Sem informação',
  paginate = false,
  perPageOptions=[],
  actionPaginate=()=> null,
  handlePerPage=()=> null,
  handleSort=()=> null,
  nls,
}) => {

  nls = nls || nlsDefault;
  const [listState, setListState] = useState([]);
  perPageOptions = perPageList.map(e=> ({...e, name: nls?.paginas?.replace('{{Id}}', e.id)}))

  //console.log("valor Registro:", nls.registro)

  useEffect(()=>{
    setListState(data)
  }, [data])

  const sortType = (e) => {
    switch (e) {
      case 'up':
        return 'down';
      case 'down':
        return 'normal';
      case 'normal':
        return 'up';
      default:
        return 'up';
    }
  }

  const sortHandle = ({sortInfo, sortColumn}) => {
    if (sortColumn) {
      handleSort(sortInfo)
    }
  }


  return (
    <>
    <div className="box-scrool">
      <table className="list-box">
        <thead>
          <tr>
            {header?.map((head) => {
              return (
                <td 
                  key={head.column}
                  className={`${head.className} ${head.sort?`list-box-header-sort ${sort?.column === head.column?`sort-${sort?.type}`: ''}`:''}`} 
                  onClick={()=> 
                    sortHandle({
                      sortColumn: head.sort, 
                      sortInfo: {
                        column: head.column, 
                        type: sort?.column === head.column ? sortType(sort.type) : 'up'
                      }
                    })
                  }
                >
                  {head.text}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            listState?.map((container, i) => {
              listCustom(container, i, data);
              const aleatorioId = numberAleatori()
              return (
                <tr key={`${container.id ? container.id : i}-${aleatorioId}`}>
                  {header.map((head) => {
                    return (
                      <td
                        className={head.className}
                        key={`${container.id ? container.id : i}-${ head.column }-${aleatorioId}`}
                      >
                        {container[head.column]
                          ? container[head.column]
                          : ''}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={header?.length} className="text-center">
                {semdadosparaexibir !== null ? semdadosparaexibir : nls.noData}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    {
      paginate?
      <PaginateList
        nls={nls}
        paginate={paginate}
        perPageOptions={perPageOptions}
        actionPaginate={actionPaginate}
        handlePerPage={handlePerPage}
      />
      :null
    }
    </>
  );
};

export const PaginateList = ({
  paginate,
  nls={},
  perPageOptions = [],
  actionPaginate = ()=> null,
  handlePerPage = ()=> null,
}) => {

  const selectedValue = e => {
    const filter = perPageOptions.filter(f=> String(f.id) === String(e))
    if (filter.length) {
      return filter[0]
    } else {
      return perPageOptions.length?perPageOptions[0]:{}
    }
  }

  return (
  <div className="list-box-actionsfooter">
    <div className="list-box-registros">
      {paginate.totalElements} {paginate.totalElements === 1 ? nls.registro : nls.registros}
    </div>
    <div className="list-box-paginacao">
      <Paginate
        data={paginate}
        action={actionPaginate}
      />
    </div>
    <div className="list-box-itensperpage">
      <Select
        name='projeto'
        label=''
        options={perPageOptions}
        selected={selectedValue(paginate?.perPage)}
        action={handlePerPage}
        selectedItem={false}
      />
    </div>
  </div>
  )
}