import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LoadingContent, Popover } from '../../components'

import './upgradeConta.scss'
import { useEffect } from 'react'
import { modalOpen, verificaConsumoConta } from '../../layout/redux/layoutActions'
import AdquirirAtivos from './content/adquirirAtivos'

//import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)

  


  useEffect(()=> {
    dispatch([verificaConsumoConta()])
  }, [sistema.idioma])

  const handleConfigurar = (s) => {
    console.warn(s, 'handleConfigurar');
    if (s) {
      dispatch(modalOpen(s))
    }
  }
  
  return (
    <div className='box-minhas-assinaturas'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
        <h3>{nls.titleList} </h3>
        </div>
        <LoadingContent
          show={false}
        >
          <Button
            onClick={e=> handleConfigurar('comprar-ativos')}
          >{nls.abrirModal}</Button>
        </LoadingContent>
      </div>
      <AdquirirAtivos/>
    </div>
  )
}
